body, html {
  margin: 0;
  padding: 0;

}

.landingContainer {
  display: flex;
  align-content: center;
  align-items: center;
  text-align: center;
}


body {
  background-color: #040a13;
  font-size: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

body .text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 100;
  font-size: 7rem;
  letter-spacing: -0.05em;
}

body .text .letter {
  color: #d9fdff;
  text-shadow: 0 0 2rem #1900ff;
  display: inline-block;
}

body .text .letter.letter-2 {
  transform: translate(-0.2rem, 1rem) rotate(10deg);
  -webkit-animation: flicker 2s ease-in-out infinite alternate;
  animation: flicker 2s ease-in-out infinite alternate;
}

body .text .letter.letter-7 {
  transform: translate(-0.2rem, 1rem) rotate(10deg);
  -webkit-animation: flicker 2s ease-in-out infinite alternate;
  animation: flicker 2s ease-in-out infinite alternate;
}

body .text .letter.letter-9 {
  transform: translate(-0.2rem, 1rem) rotate(90deg);
  -webkit-animation: flicker 2s ease-in-out infinite alternate;
  animation: flicker 2s ease-in-out infinite alternate;
}

body .text .letter.letter-12 {
  transform: translate(5rem, -0.9rem) rotate(180deg);
  -webkit-animation: flicker 2s ease-in-out infinite alternate;
  animation: flicker 2s ease-in-out infinite alternate;
}

body .text .letter.letter-17 {
  transform: translate(-0.2rem, 1rem) rotate(90deg);
  -webkit-animation: flicker 2s ease-in-out infinite alternate;
  animation: flicker 2s ease-in-out infinite alternate;
}

body .text .letter.letter-25 {
  transform: translate(-0.2rem, 1rem) rotate(90deg);
  -webkit-animation: flicker 2s ease-in-out infinite alternate;
  animation: flicker 2s ease-in-out infinite alternate;
}

body .text .letter.letter-27 {
  transform: translate(-0.2rem, 1rem) rotate(10deg);
  -webkit-animation: flicker 2s ease-in-out infinite alternate;
  animation: flicker 2s ease-in-out infinite alternate;
}



@-webkit-keyframes flicker {
  0% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  5.5% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  6% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  6.5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  7% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  8% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  50% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(13deg);
  }

  100% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  5.5% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  6% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  6.5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  7% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  8% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  50% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(13deg);
  }

  100% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
}